import { useRef } from "react";

import emptyHeart from "../../assets/components/products/coffee-shaped-heart-empty.svg";
import fullHeart from "../../assets/components/products/coffee-shaped-heart-full.svg";

import "./product.styles.scss";

const Product = ({ product, modalRef, setModalProduct }) => {
  const moreButtonRef = useRef("");

  const {
    image,
    name,
    strength,
    price,
    description,
    ingredients,
    flavors,
    options,
    subCategories,
  } = product;

  let coffeStrength = [];
  const MAX_COFFEE_STRENGTH = 5;
  for (let i = 0; i < MAX_COFFEE_STRENGTH; i += 1) {
    if (i < strength) {
      coffeStrength.push(
        <img
          key={i}
          src={fullHeart}
          alt="Logo"
          className="product-body-strength__image"
        />
      );
    } else {
      coffeStrength.push(
        <img
          key={i}
          src={emptyHeart}
          alt="Logo"
          className="product-body-strength__image"
        />
      );
    }
  }

  const divStrength = Array.isArray(subCategories) &&
    subCategories.includes("Café") && (
      <div className="product-body-strength">
        <span className="product-body-strength__title">Intensidade: </span>
        {coffeStrength}
      </div>
    );

  const divFlavors = Array.isArray(flavors) && flavors.length > 0 && (
    <div className="product-body-flavors">
      <span className="product-body-flavors__title">Sabores: </span>
      {flavors.join(", ")}
    </div>
  );

  const allPrices = [];
  let divOptions = null;

  if (Array.isArray(options)) {
    const allOptionsName = [];
    price && allPrices.push(price);

    options.forEach((option) => {
      allOptionsName.push(option.name);
      allPrices.push(option.price);
    });

    divOptions = (
      <div className="product-body-options">
        <span className="product-body-options__title">Opções: </span>
        {allOptionsName.join(", ")}
      </div>
    );
  }

  let divPrices = null;
  if (Array.isArray(allPrices) && allPrices.length > 1) {
    price && allPrices.push(price);
    const lowestPrice = Math.min(...allPrices);
    const biggestPrice = Math.max(...allPrices);

    const overallPrice =
      lowestPrice === biggestPrice
        ? `R$: ${Number(lowestPrice).toFixed(2)}`
        : `R$: ${Number(lowestPrice).toFixed(2)} a ${Number(
            biggestPrice
          ).toFixed(2)}`;

    divPrices = (
      <div className="product-body-footer__price">{overallPrice}</div>
    );
  } else if (Array.isArray(allPrices) && allPrices.length === 1) {
    divPrices = (
      <div className="product-body-footer__price">
        R$: {Number(allPrices[0]).toFixed(2)}
      </div>
    );
  } else if (price) {
    divPrices = (
      <div className="product-body-footer__price">
        R$: {Number(price).toFixed(2)}
      </div>
    );
  }

  const onMouseOverHandler = (event) => {
    if (!!moreButtonRef.current) event.target.classList.add("product--cursor");
  };

  const onClickHandler = () => {
    if (!!moreButtonRef.current) {
      setModalProduct(product);
      modalRef.current.classList.remove("modal--close");
    }
  };

  return (
    <div
      className="product"
      onMouseOver={onMouseOverHandler}
      onClick={onClickHandler}
    >
      {image ? (
        <div
          className="product-header__image"
          style={{
            backgroundImage: `url("${image}")`,
          }}
        ></div>
      ) : (
        <div className="product-header__image"></div>
      )}
      <div className="product-body">
        <div className="product-body__name">{name}</div>
        {divStrength}
        {divFlavors}
        {divOptions}
        <div className="product-body-footer">
          {divPrices}
          <div
            className="product-body-footer__more"
            onClick={onClickHandler}
            ref={moreButtonRef}
          >
            Saber mais
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
